import { HttpErrorResponse } from '@angular/common/http'
import { Injectable, Inject, EventEmitter } from '@angular/core'
import { SentryErrorHandler, ErrorHandlerOptions } from '@sentry/angular-ivy'

export interface Options extends ErrorHandlerOptions {}

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService extends SentryErrorHandler {
  /** Emits each time an error is handled by this instance */
  onError = new EventEmitter()

  constructor(@Inject('options') options?: ErrorHandlerOptions) {
    super(options)
    this._options.extractor = (e: any) =>
      e?.rejection || this._defaultExtractor(e)
  }

  setShowDialog(show: boolean) {
    this._options.showDialog = show
  }

  override handleError(e: any) {
    /**
     * Loading chunk error
     */
    const chunkFailedMessage = /Loading chunk [a-zA-Z0-9_\-.]+ failed/

    if (chunkFailedMessage.test(e?.message)) {
      window.location.reload()
    }

    e = this._extractError(e)
    this.onError.emit(e)

    if (e instanceof HttpErrorResponse && e.status > 400 && e.status < 409) {
      return
    }

    super.handleError(e)
  }
}
