import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'

import {
  Order,
  OrderAdditionalPackage,
  OrderDetail,
  OrderField,
  OrderNotification,
  OrderPackage,
  OrderStatus,
} from '../order.model'
import {
  EMPTY,
  Observable,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs'
import { OrdersRepository } from '../orders.repository'
import { HttpErrorResponse } from '@angular/common/http'
import { ORDER_ACTIONS } from '../order.const'
import { ShippingAddress } from '../../../models/address.model'
import { Printers } from '../../print-node'
import { getOrderPickedQty } from '../libs/order-picking.lib'
import { isEqual } from 'lodash'
import { OrdersService } from '../orders.service'

interface OrderDetailState extends OrderDetail {
  orderId?: string
  error?: HttpErrorResponse
  fields: OrderField[]
  notification?: OrderNotification
  printers?: Printers
  toClose: boolean
  isLoading: boolean
  isModified: boolean
}

const ORDER_DETAIL_INITIAL_STATE: OrderDetailState = {
  fields: ['_id'],
  toClose: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class OrderDetailUseCase extends ComponentStore<OrderDetailState> {
  constructor(
    private ordersRepository: OrdersRepository,
    private ordersService: OrdersService,
  ) {
    super(ORDER_DETAIL_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$ = this.select((state) => state)

  readonly selectFields$ = this.select((state) => state.fields)

  readonly selectIsLoading$ = this.select((state) => state.isLoading)

  readonly selectOrderId$ = this.selectState$.pipe(
    map((state) => state.orderId),
  )

  readonly selectIsModified$ = this.selectState$.pipe(
    map((state) => state.isModified),
  )

  readonly selectToClose$ = this.selectState$.pipe(
    map((state) => state.toClose),
  )

  readonly selectOrder$ = this.selectState$.pipe(map((state) => state.order))

  readonly selectOrderStatus$ = this.selectOrder$.pipe(
    map((order) => order?.status),
  )
  readonly selectOrderShippingAddress$ = this.selectOrder$.pipe(
    map((order) => order?.header.shippingAddress),
  )

  readonly selectOrderPickedQty$ = this.selectOrder$.pipe(
    map((order) => (order ? getOrderPickedQty(order) : 0)),
  )

  readonly selectOrderPackages$ = this.selectOrder$.pipe(
    map((order) => order?.packages),
  )

  readonly selectOrderAdditionalPackages$ = this.selectOrder$.pipe(
    map((order) => order?.additionalPackages),
  )

  readonly selectPermissions$ = this.selectOrderStatus$.pipe(
    map((status) => (status ? ORDER_ACTIONS[status].permissions : [])),
  )

  readonly selectExtData$ = this.select((state) => state.extData)

  readonly selectWarehouse$ = this.selectExtData$.pipe(
    map((extData) => extData?.warehouse),
  )

  readonly selectCarrier$ = this.selectExtData$.pipe(
    map((extData) => extData?.carrier),
  )

  readonly selectNotification$ = this.selectState$.pipe(
    map((state) => state.notification),
  )

  // Effects

  readonly load$ = this.effect((orderId$: Observable<string>) => {
    return orderId$.pipe(
      tap((orderId) => this.setOrderId(orderId)),
      withLatestFrom(this.selectFields$),
      switchMap(([orderId, fields]) =>
        this.ordersRepository.loadOrder$(orderId, fields).pipe(
          tapResponse(
            (orderData) => this.setOrderData(orderData),
            (error) => this.parseError(error),
          ),
        ),
      ),
    )
  })

  readonly save$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectState$),
      switchMap(([toClose, state]) => {
        if (!state.order) {
          return EMPTY
        }

        return this.ordersRepository.saveOrder$(state.order, state.fields).pipe(
          tapResponse(
            (orderData) =>
              this.setOrderSavedData({
                orderData,
                toClose,
                notification: { code: 'ORDER_SAVED', type: 'success' },
              }),
            (error) => this.parseError(error),
          ),
        )
      }),
    )
  })

  readonly printLabel$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectState$),
      switchMap(([toClose, state]) => {
        if (!state.order || !state.printers) {
          return EMPTY
        }

        const printers = state.printers
        const obs$ = state.isModified
          ? this.ordersService.update$(state.order._id, state.order)
          : of(state.order)

        return obs$.pipe(
          switchMap((order) => {
            const ord$ =
              order.status === OrderStatus.processed
                ? this.ordersService.pack$(order._id, { printers })
                : this.ordersService
                    .printLabel$(order._id, printers)
                    .pipe(map(() => order))

            return ord$.pipe(
              tapResponse(
                (order) =>
                  this.setOrderSaved({
                    order,
                    toClose,
                    notification: { code: 'ORDER_PRINTED', type: 'success' },
                  }),
                (error) => this.parseError(error),
              ),
            )
          }),
        )
      }),
    )
  })

  readonly cancelShipment$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectOrder$),
      switchMap(([_, order]) => {
        if (!order) {
          return EMPTY
        }

        return this.ordersService.cancelShipment$(order._id).pipe(
          switchMap(() => this.ordersService.read$(order._id)),
          tapResponse(
            (order) => this.setOrder(order),
            (error) => this.parseError(error),
          ),
        )
      }),
    )
  })

  readonly requestShipment$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      tap(() => this.setIsLoading(true)),
      withLatestFrom(this.selectOrder$),
      switchMap(([_, order]) => {
        if (!order) {
          return EMPTY
        }

        return this.ordersService.requestShipment$(order._id).pipe(
          switchMap(() => this.ordersService.read$(order._id)),
          tapResponse(
            (order) => this.setOrder(order),
            (error) => this.parseError(error),
          ),
        )
      }),
    )
  })

  // Reducers

  readonly setOptions = this.updater(
    (state, newState: Partial<OrderDetailState>): OrderDetailState => {
      return { ...state, ...newState }
    },
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): OrderDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setPrinters = this.updater(
    (state, printers: Printers): OrderDetailState => {
      return {
        ...state,
        printers,
      }
    },
  )

  readonly setFields = this.updater(
    (state, fields: OrderField[]): OrderDetailState => {
      return {
        ...state,
        fields,
      }
    },
  )

  readonly setOrderId = this.updater(
    (state, orderId: string): OrderDetailState => {
      return {
        ...state,
        orderId,
        order: undefined,
        isLoading: true,
        isModified: false,
      }
    },
  )

  readonly setOrderData = this.updater(
    (state, orderData: OrderDetail): OrderDetailState => {
      const { order, extData } = orderData
      return {
        ...state,
        order,
        extData,
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly setOrderSavedData = this.updater(
    (
      state,
      savedData: {
        orderData: OrderDetail
        toClose: boolean
        notification?: OrderNotification
      },
    ): OrderDetailState => {
      const { orderData, toClose, notification } = savedData
      const { order, extData } = orderData
      return {
        ...state,
        order,
        extData,
        notification,
        toClose,
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly setOrderSaved = this.updater(
    (
      state,
      savedData: {
        order: Order
        toClose: boolean
        notification?: OrderNotification
      },
    ): OrderDetailState => {
      const { order, toClose, notification } = savedData
      return {
        ...state,
        order,
        toClose,
        notification,
        isLoading: false,
        isModified: false,
      }
    },
  )

  readonly setNotification = this.updater(
    (
      state,
      notificationData: { notification: OrderNotification; toClose: boolean },
    ): OrderDetailState => {
      const { notification, toClose } = notificationData
      return {
        ...state,
        notification,
        toClose,
      }
    },
  )

  readonly setOrder = this.updater((state, order: Order): OrderDetailState => {
    return {
      ...state,
      order,
      isLoading: false,
      isModified: true,
    }
  })

  readonly unsetOrder = this.updater((state): OrderDetailState => {
    return {
      ...state,
      orderId: undefined,
      order: undefined,
      error: undefined,
      notification: undefined,
      toClose: false,
      isLoading: false,
      isModified: false,
    }
  })

  readonly setShippingAddress = this.updater(
    (state, shippingAddress: ShippingAddress): OrderDetailState => {
      if (!state.order) {
        return state
      }

      const order = state.order
      order.header.shippingAddress = shippingAddress

      return {
        ...state,
        order,
        isModified: true,
      }
    },
  )

  readonly setPackages = this.updater(
    (state, packages: OrderPackage[] | undefined): OrderDetailState => {
      if (!state.order || isEqual(state.order.packages, packages)) {
        return state
      }

      return {
        ...state,
        order: {
          ...state.order,
          packages,
        },
        isModified: true,
      }
    },
  )

  readonly setAdditionalPackages = this.updater(
    (
      state,
      additionalPackages: OrderAdditionalPackage[] | undefined,
    ): OrderDetailState => {
      if (
        !state.order ||
        isEqual(state.order.additionalPackages, additionalPackages)
      ) {
        return state
      }

      return {
        ...state,
        order: {
          ...state.order,
          additionalPackages,
        },
        isModified: true,
      }
    },
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): OrderDetailState => {
      return {
        ...state,
        error,
        isLoading: false,
      }
    },
  )

  readonly reset = this.updater(
    (state): OrderDetailState => ORDER_DETAIL_INITIAL_STATE,
  )

  // Utilities

  private parseError(errorResponse: unknown) {
    if (errorResponse instanceof HttpErrorResponse) {
      this.setError(errorResponse)
    }
  }
}
